<template>
  <b-modal
    v-model="myShow"
    ok-title="OK"
    cancel-title="CANCEL"
    centered
    :title="getTitle"
  >

    <div v-if="report">
      <div
        v-for="detailList in getDetailLists"
        :key="detailList.title"
        class="d-flex flex-row align-items-center py-1"
      >
        <div class="list-title font-weight-bolder">
          {{ $t(detailList.title) }} :
        </div>
        <div
          class="list-text w-100"
          style="user-select: text"
        >
          {{ detailList.value }}
        </div>
      </div>

      <!-- bet details lists -->
      <div class="">
        <table class="table table-bordered">
          <tbody>
            <tr
              v-for="(betList, betListKey, index) in getBetLists"
              :key="betList.BetID"
            >
              <td>{{ index }}</td>
              <td>
                <span>Bet ID : {{ betList.BetID }}</span><br>
                <span>Bet TransactionID : {{ betList.TransactionID }}</span><br>
                <span>Bet type : <b>{{ betList.BetType }}</b></span><br>
                <span>Bet Amount : {{ betList.BetAmount }}</span><br>
                <span>Result Amount :
                  <b :class="[Number(betList.ResultAmount) < 0 ? 'red--text' : 'black--text']">{{ betList.ResultAmount }}</b></span><br>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- bet result -->
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td style="width: 15%">
                Result
              </td>
              <td style="width: 85%">
                <span
                  style="padding:5px;"
                  class="text-white"
                  :class="classResultPoint"
                >
                  {{ report.BetResult.Point }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- bet result total -->
        <div class="d-flex flex-row align-items-center">
          <div class="list-title font-weight-bolder">
            Bet Amount
          </div>
          <div
            class="list-text w-100 h2"
            style="user-select: text"
          >
            <b-badge
              variant="light-primary"
              class="badge-glow"
            >
              {{ totalBetAmount | formatNumberMoney }}
            </b-badge>
          </div>
        </div>

        <div class="d-flex flex-row align-items-center">
          <div class="list-title font-weight-bolder">
            Winlose
          </div>
          <div
            class="list-text w-100 h2"
            style="user-select: text"
          >
            <span
              class=""
              :class="[Number(totalWinlose) < 0 ? 'red--text' : 'black--text']"
            >
              {{ totalWinlose | formatNumberMoney }}
            </span>
          </div>
        </div>

      </div>
    </div>

    <template #modal-footer>
      <div class="d-flex justify-content-end w-100">
        <div>
          <b-button
            variant="primary"
            size="sm"
            @click="$emit('update:show', false)"
          >
            {{ $t('Close') }}
          </b-button>
        </div>
      </div>
    </template>

  </b-modal>
</template>

<script>

import { DatetimeFormat } from '@/utils/date-format'

export default {
  props: {
    show: { type: Boolean, required: true },
    report: { type: Object, required: false, default: null },
    reportItem: { type: Object, required: true },
  },
  data() {
    return {
      // show: false,
    }
  },
  computed: {
    // rot
    classResultPoint() {
      const resultDetailRRRed = this.report?.BetResult?.ResultRed
      const resultDetailRRBlack = this.report?.BetResult?.ResultBlack
      const resultDetailRRZero = this.report?.BetResult?.ResultGreen

      if (resultDetailRRZero) {
        return {
          'bg-green': true,
        }
      }
      return {
        'bg-danger': resultDetailRRRed,
        'bg-dark': resultDetailRRBlack,
      }
    },

    getTitle() {
      return `Casino Sa Report Detail`
    },

    getDetailLists() {
      return [
        { title: `Game`, value: this.reportItem.game?.game },
        { title: `Date/Time`, value: DatetimeFormat(this.report?.gameStartDate) },
        { title: `Round ID`, value: this.report?.gameId },
      ]
    },

    getBetLists() {
      const betDetails = this.report?.BetDetailLists
      if (!betDetails) {
        return []
      }

      return Array.isArray(betDetails) ? Array.from(betDetails) : [betDetails]
    },
    totalBetAmount() {
      const bets = this.getBetLists
      return Array.from(bets).reduce((prev, curr) => {
        prev += parseFloat(curr.BetAmount)
        return prev
      }, 0)
    },
    totalWinlose() {
      const bets = this.getBetLists
      return Array.from(bets).reduce((prev, curr) => {
        prev += parseFloat(curr.ResultAmount)
        return prev
      }, 0)
    },
    myShow: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      },
    },
  },
  methods: {
    DatetimeFormat,
  },
}
</script>

<style>
.list-main {
    border: 1px solid #e1e1e1;
    padding: 10px 0 10px 0;
    border-radius: 5px;
}
.list-title {
    flex-basis: 40%;
    text-align: right;
    padding-right: 6px;
}
.list-text-border {
    border: 1px solid #e1e1e1;
    padding: 10px 0px 10px 0;
    border-radius: 8px;
    padding-left: 10px;
}

</style>
