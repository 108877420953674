import dayjs from 'dayjs'

const DatetimeFormat = datetime => {
  const date = dayjs(datetime)
  return date.isValid() ? date.format('YYYY-MM-DD HH:mm:ss') : null
}

export {
  DatetimeFormat,
}

export const _ = undefined
